/* TourDate tour cell
   ========================================================================== */

.schedule-table td.tour-date {
    cursor: pointer;

    &.update-pending {
        padding: 8px;
        background-color: #e3e3e3;
        cursor: default;
    }

    &.undefined,
    &.inactive {
        background-color: #e3e3e3;
    }

    &.canceled {
        background-color: #ff0202;
        color: #e3e3e3;
    }

    &.open {
        background-color: #feffcb;

        > span {
            margin: 0 3px;
        }
    }

    &.active {
    }
}
