/* Action bar
   ========================================================================== */

.ui-action-bar {
    @extend .clear-fix;
    margin: 0 0 10px;

    .primary-actions {
        float: right;
    }

    .secondary-actions {
        float: left;

        > * {
            vertical-align: middle;
        }
    }
}
