/* Modal
   ========================================================================== */
@import "../mixins";

// overlay
.ui-modal-overlay {
    background: rgba(255, 255, 255, 0.6);
    height: 100%;
    left: 0;
    overflow: auto;
    padding: 100px;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
}

// modal
.ui-modal {
    $padding-v: 25px;
    $padding-h: 30px;

    @include box-shadow;
    background-color: #f7f7f7;
    display: inline-block;
    text-align: left;
    min-width: 560px;

    // title
    .modal-title {
        padding: 15px $padding-h;
        line-height: 45px;
        font-size: 32px;
        font-weight: 700;
        background-color: #f3f3f3;
        margin: 0;

        span {
            font-weight: 400;
            font-size: 16px;
        }
    }

    // content
    .modal-content {
        margin: $padding-v $padding-h;
        overflow-x: auto;

        p {
            margin: 10px 0;
            font-size: 16px;
        }

        .panel {
            margin: 0 (-$padding-h);
        }
    }

    // actions
    .primary-actions {
        float: right;
        padding: 0 $padding-h $padding-v 0;
    }
    .secondary-actions {
        float: left;
        padding: 0 0 $padding-v $padding-h;
    }
}
