/* Calendar page
   ========================================================================== */

.calendar-page {
    // calendar table
    table {
        width: 100%;
        border: 3px solid #fff;
        table-layout: fixed;

        th {
            color: #aeaeae;
            font-size: 20px;
            text-align: left;
            padding: 2px 4px;
            height: 45px;
            font-weight: 400;
            border: 3px solid #fff;
        }

        td {
            background-color: #e5e5e5;
            height: 90px;
            border: 3px solid #fff;
            padding: 2px 4px;
            vertical-align: top;
            color: #868686;
            cursor: pointer;

            // days
            &.today {
                background: #000;
                color: #fff;
            }

            &.not-in-month {
                background: none;
            }

            &.event {
                background: #9a9a9a;
                color: #333;
            }

            > div {
                margin: 0 0 5px;
                font-size: 20px;

                .event-name {
                    display: block;
                    font-size: 16px;
                }
            }
        }
    }
}
