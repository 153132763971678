/* Header
   ========================================================================== */
@import "../vars";
@import "../colors";

.header {
    @extend .clear-fix;
    padding: 15px $container-padding;

    .logo {
        float: right;
        display: table;

        strong {
            color: #dc0c31;
            font-size: 12px;
            font-weight: 400;
            line-height: 1;
            display: table-cell;
            vertical-align: middle;
            text-align: right;
        }

        img {
            float: left;
            display: block;
            width: 60px;
            height: auto;
            margin: 0 0 0 5px;
        }
    }
}
