/* Driver group overview modal
   ========================================================================== */

.driver-group-overview-modal {
    .schedule-table th.title {
        min-width: 140px;
    }

    .schedule-table th.additional {
        min-width: 60px;
    }

    .schedule-table th.driver {
        min-width: 80px;
        text-align: center;
    }
}
