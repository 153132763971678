/* Form: Field
   ========================================================================== */

.ui-field {
    margin: 5px;
    display: block;
    vertical-align: top;

    // label
    > label {
        display: inline-block;
        float: left;
        font-weight: 700;
        padding: 5px 10px 5px 5px;
    }

    // widget
    .widget {
        display: inline-block;
        padding: 5px 0;
        position: relative;

        span.description {
            display: block;
            color: #a1a1a1;
            font-style: italic;
        }
    }

    &.error {
        .ui-text,
        .ui-choice {
            outline: 1px solid red;
        }
    }
}
