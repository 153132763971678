/* Form: output collection
   ========================================================================== */

.ui-collection-output {
    display: inline-block;

    table {
        display: flex;
        flex-direction: column;
        overflow: auto;
        border: 1px solid $table-color;

        tr {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            flex-shrink: 0;
        }

        td,
        th {
            display: block;
            flex: 1 1 60px;
            line-height: 30px;
            padding: 2px 0 2px 10px;
            overflow: hidden;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        thead {
            background-color: $table-color;
            font-size: 18px;
        }

        tbody {
            overflow-y: scroll;
            background-color: #fff;
            height: 100%;
        }
    }
}
