/* General
   ========================================================================== */

.clear-fix {
    &::after {
        clear: both;
        content: "";
        display: table;
    }
}

* {
    box-sizing: border-box;
}

table {
    width: 100%;
}
