/* Animations
   ========================================================================== */

@mixin vertical-bar($duration, $start) {
    animation: vertical-bars $duration linear $start infinite;
}

.ui-animation {
    display: inline-block;
    vertical-align: middle;

    // loading bars
    &.loading-bars {
        height: 32px;
        padding: 12px 0;
        width: 32px;

        &.bars-3 {
            padding-left: 6px;
        }

        div {
            background-color: #000;
            float: left;
            height: 8px;
            margin: 0 0 0 2px;
            width: 4px;

            @for $i from 1 through 5 {
                $start: 0.2s * $i - 0.2s;

                &.vertical-bar-#{$i} {
                    @include vertical-bar(1.2s, $start);
                }
            }
        }
        &.loading-bars.animation-small {
            height: 25px;
            padding: 9px 1px;
            width: 25px;

            &.bars-3 {
                padding-left: 6px;
            }

            div {
                height: 6px;
                width: 3px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

/*  keyframes */
@keyframes vertical-bars {
    0% {
        transform: scaleY(1);
    }

    20% {
        transform: scaleY(3);
    }

    50% {
        transform: scaleY(1);
    }

    100% {
        transform: scaleY(1);
    }
}
