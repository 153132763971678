/* Flash-bar
   ========================================================================== */
@import "../vars";
@import "../colors";

.ui-flash-bar {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 10px;

    // default: info
    color: $flash-info;
    background-color: #90d4eb;

    > * {
        margin: 0 5px;
    }

    em {
        text-decoration: underline;
    }

    &.success {
        color: $flash-success;
        background-color: #91eb91;
    }

    &.warning {
        color: $flash-warning;
        background-color: #ffffcf;
    }

    &.error {
        color: $flash-error;
        background-color: #fe9a9b;
    }

    &.info {
        color: $flash-info;
        background-color: #90d4eb;
    }

    .ui-icon {
        float: left;
    }
}
