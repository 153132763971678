/* ==========================================================================
   Fonts
   ========================================================================== */
@import "./functions";

// Open Sans
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,700,700italic");

// Icons Font
@font-face {
    font-family: "IconsFont";
    src: font-url("../assets/fonts/IconsFont.eot");
    src: font-url("../assets/fonts/IconsFont.eot?#iefix") format("embedded-opentype"),
        font-url("../assets/fonts/IconsFont.woff") format("woff"),
        font-url("../assets/fonts/IconsFont.ttf") format("truetype"),
        font-url("../assets/fonts/IconsFont.svg#IconsFont") format("svg");
    font-weight: normal;
    font-style: normal;
}
