// ==========================================================================
// Colors
// ==========================================================================

// text
$color-text: #333333;
$color-text-hover: #333333;
$color-text-inverted: #ffffff;
$color-text-inverted-hover: #ffffff;
$color-text-inactive: #d4d4cd;

// blocks
$background-color-menu: #000000;
$background-color-menu-hover: #515151;
$background-color-menu-active: #d91a38;
$background-color-panel: #f2f2f2;
$background-color-header: #eeeeee;
$background-color-break: #cbcbcb;
$background-color-inactive: #ebebe4;

// buttons
$background-color-info: #5595df;
$background-color-positive: #1e9017;
$background-color-success: #86cd9f;
$background-color-neutral: #c2c2c2;
$background-color-warning: #fff4a5;
$background-color-negative: #fa0019;
$background-color-error: #d91a38;

// light
$darken_button: 5%;
$darken_block: 5%;

$flash-info: #2c60a5;
$flash-success: #227323;
$flash-warning: #96904d;
$flash-error: #872325;

$table-color: #f3f3f3;
$delete-color: #fc0d1b;

$ui-panel-color: #f2f2f2;
