// Fragment: Absence types
// ========================================================================== */

&.free {
    background-color: #e3e3e3;
}

&.unknown {
    background-color: #525252;
    color: #e3e3e3;
}

&.reserve {
    background-color: #feffcb;
}

&.holiday {
    background-color: #fbd4b4;
}

&.allowances {
    background-color: #ff67ff;
}

&.sick-leave {
    background-color: #ff0202;
    color: #e3e3e3;
}

&.accident {
    background-color: #ffff02;
}

&.unpaid {
    background-color: #02ffff;
}

&.paid {
    background-color: #e36d0b;
}

&.vacation {
    background-color: #9acccc;
}

&.paid-vacation {
    background-color: #7131a0;
    color: #e3e3e3;
}

&.compensation {
    background-color: #67cc34;
}

&.flex-free {
    background-color: #e3e3e3;
}

&.flexible {
    background-color: #5c63cc;
    color: #e3e3e3;
}
