/* App
   ========================================================================== */

body {
    background: #fff;
    color: #333;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
}

.container {
    width: 995px;
    margin: 0 auto;
    padding: 0 0 100px;
}

.content-centered {
    margin: 125px 0;
    text-align: center;
    font-size: 16px;

    p {
        margin: 25px 0;
    }
}

.content-loading {
    margin: 45px auto;
    height: 32px;
    width: 32px;
}

.query-string-filter {
    margin: 5px;
    display: inline-block;

    > label {
        display: inline-block;
        float: left;
        font-weight: 700;
        padding: 5px 10px 5px 5px;
    }
}

.footer {
    margin: 35px 0;
    text-align: right;
    color: #c0c0c0;
}
