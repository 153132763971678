/* Form: DeadlineTime
   ========================================================================== */

.ui-deadline {
    .days-before-choice,
    .time-choice {
        display: inline-block;
        float: left;
    }

    .days-before-choice .ui-choice,
    .time-choice .ui-choice {
        > select {
            padding: 0;
            width: 55px;
        }
    }
}
