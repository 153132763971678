/* Simple grid
   ========================================================================== */
@import "../vars";
@import "../colors";

@for $i from 1 through 16 {
    .ui-col-#{$i} {
        width: $ui-grid-unit * $i;
    }

    .ui-row-#{$i} {
        height: $ui-grid-unit * $i;
    }
}
