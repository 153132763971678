/* Date navigation
   ========================================================================== */

.ui-date-navigation {
    line-height: 35px;
    display: inline-block;

    .label {
        padding: 0 20px;
    }
}
