@import "https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,700,700italic";
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

@font-face {
  font-family: IconsFont;
  src: url("IconsFont.bb928879.eot");
  src: url("IconsFont.bb928879.eot#iefix") format("embedded-opentype"), url("IconsFont.9aaea01f.woff") format("woff"), url("IconsFont.ce79f19c.ttf") format("truetype"), url("IconsFont.2ab2d55a.svg#IconsFont") format("svg");
  font-weight: normal;
  font-style: normal;
}

.clear-fix:after, .tour-date-driver-select:after, .header:after, .ui-action-bar:after {
  clear: both;
  content: "";
  display: table;
}

* {
  box-sizing: border-box;
}

table {
  width: 100%;
}

.ui-action-bar {
  margin: 0 0 10px;
}

.ui-action-bar .primary-actions {
  float: right;
}

.ui-action-bar .secondary-actions {
  float: left;
}

.ui-action-bar .secondary-actions > * {
  vertical-align: middle;
}

.ui-animation {
  vertical-align: middle;
  display: inline-block;
}

.ui-animation.loading-bars {
  height: 32px;
  width: 32px;
  padding: 12px 0;
}

.ui-animation.loading-bars.bars-3 {
  padding-left: 6px;
}

.ui-animation.loading-bars div {
  float: left;
  height: 8px;
  width: 4px;
  background-color: #000;
  margin: 0 0 0 2px;
}

.ui-animation.loading-bars div.vertical-bar-1 {
  animation: 1.2s linear infinite vertical-bars;
}

.ui-animation.loading-bars div.vertical-bar-2 {
  animation: 1.2s linear .2s infinite vertical-bars;
}

.ui-animation.loading-bars div.vertical-bar-3 {
  animation: 1.2s linear .4s infinite vertical-bars;
}

.ui-animation.loading-bars div.vertical-bar-4 {
  animation: 1.2s linear .6s infinite vertical-bars;
}

.ui-animation.loading-bars div.vertical-bar-5 {
  animation: 1.2s linear .8s infinite vertical-bars;
}

.ui-animation.loading-bars.loading-bars.animation-small {
  height: 25px;
  width: 25px;
  padding: 9px 1px;
}

.ui-animation.loading-bars.loading-bars.animation-small.bars-3 {
  padding-left: 6px;
}

.ui-animation.loading-bars.loading-bars.animation-small div {
  height: 6px;
  width: 3px;
}

.ui-animation.loading-bars.loading-bars.animation-small div:first-child {
  margin-left: 0;
}

@keyframes vertical-bars {
  0% {
    transform: scaleY(1);
  }

  20% {
    transform: scaleY(3);
  }

  50% {
    transform: scaleY(1);
  }

  100% {
    transform: scaleY(1);
  }
}

.ui-button {
  vertical-align: top;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 25px;
  color: #333;
  background-color: #c2c2c2;
  border: 1px solid #c2c2c2;
  outline: 0;
  margin: 0 1px;
  padding: 0 5px;
  font-size: 12px;
  font-weight: 700;
  line-height: 23px;
  text-decoration: none;
  display: inline-block;
}

.ui-button:disabled {
  opacity: .5;
}

.ui-button:hover:not(:disabled) {
  background-color: #b5b5b5;
}

.ui-button.btn-positive {
  color: #fff;
  background-color: #1e9017;
  border-color: #1e9017;
}

.ui-button.btn-positive:hover:not(:disabled) {
  background-color: #197a13;
}

.ui-button.btn-neutral {
  color: #333;
  background-color: #c2c2c2;
  border-color: #c2c2c2;
}

.ui-button.btn-neutral:hover:not(:disabled) {
  background-color: #b5b5b5;
}

.ui-button.btn-negative {
  color: #fff;
  background-color: #fa0019;
  border-color: #fa0019;
}

.ui-button.btn-negative:hover:not(:disabled) {
  background-color: #e10016;
}

.ui-button.btn-warning {
  color: #333;
  background-color: #fff4a5;
  border-color: #fff4a5;
}

.ui-button.btn-warning:hover:not(:disabled) {
  background-color: #fff18c;
}

.ui-button.btn-warning.btn-secondary:hover:not(:disabled) {
  color: #333;
}

.ui-button.btn-info {
  color: #fff;
  background-color: #5595df;
  border-color: #5595df;
}

.ui-button.btn-info:hover:not(:disabled) {
  background-color: #4088db;
}

.ui-button.btn-secondary {
  color: #333;
  background-color: #f2f2f2;
  border-width: 1px;
}

.ui-button.btn-secondary:hover:not(:disabled) {
  color: #fff;
}

.ui-button.btn-small {
  height: 15px;
  padding: 0 5px;
  font-size: 10px;
  font-weight: 700;
  line-height: 13px;
}

.ui-button.btn-regular {
  height: 15px;
  padding: 0 5px;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
}

.ui-button.btn-large {
  height: 35px;
  width: auto;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 700;
  line-height: 33px;
}

.ui-button.btn-x-large {
  height: 45px;
  padding: 0 25px;
  font-size: 16px;
  font-weight: 700;
  line-height: 43px;
}

.ui-button.btn-icon-small {
  width: 15px;
  height: 15px;
  padding: 0;
  line-height: 13px;
}

.ui-button.btn-icon, .ui-button.btn-icon-regular {
  width: 25px;
  height: 25px;
  padding: 0;
  line-height: 23px;
}

.ui-button.btn-icon-large {
  width: 35px;
  height: 35px;
  padding: 0;
  line-height: 33px;
}

.ui-button.btn-icon-x-large {
  width: 45px;
  height: 45px;
  padding: 0;
  line-height: 43px;
}

.ui-button.btn-link {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  font-weight: 400;
  text-decoration: underline;
}

.ui-button.btn-link:hover:not(:disabled) {
  background-color: rgba(0, 0, 0, 0);
}

.ui-button.btn-rounded {
  border-radius: 50px;
}

.ui-button.btn-no-borders {
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

.ui-button.btn-no-borders:hover:not(:disabled) {
  background-color: rgba(0, 0, 0, 0);
}

.ui-button .ui-icon {
  vertical-align: top;
  margin: 0 3px;
}

.confirmation-button {
  display: inline-block;
}

.ui-checkbox {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  line-height: 25px;
  display: block;
}

.ui-checkbox.inline {
  display: inline-block;
}

.ui-checkbox input[type="checkbox"] {
  margin: 0 5px 0 0;
}

.ui-choice {
  display: inline-block;
}

.ui-choice.auto-select .ui-button, .ui-choice.auto-select .ui-element-update-pending {
  float: right;
}

.ui-choice.auto-select .choices {
  clear: both;
  background-color: #fff;
  border: 1px solid #f3f3f3;
  padding: 4px 20px;
  overflow-y: auto;
}

.ui-choice select {
  color: #000;
  height: 25px;
  border: 1px solid #ccc;
  outline: 0;
  padding: 2px 4px;
  font-size: 13px;
}

.ui-collection .ui-field {
  display: inline-block;
}

.ui-collection .ui-field.actions .ui-icon {
  cursor: pointer;
  margin: 0 5px 0 0;
  padding: 5px 0;
  line-height: 25px;
}

.ui-collection .ui-field.actions .ui-icon:hover {
  color: #fa0019;
}

.ui-collection-output {
  display: inline-block;
}

.ui-collection-output table {
  border: 1px solid #f3f3f3;
  flex-direction: column;
  display: flex;
  overflow: auto;
}

.ui-collection-output table tr {
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
}

.ui-collection-output table td, .ui-collection-output table th {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 60px;
  padding: 2px 0 2px 10px;
  line-height: 30px;
  display: block;
  overflow: hidden;
}

.ui-collection-output table thead {
  background-color: #f3f3f3;
  font-size: 18px;
}

.ui-collection-output table tbody {
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
}

.ui-date-time {
  display: inline-block;
}

.ui-date-time .date-input, .ui-date-time .time-input {
  float: left;
  display: inline-block;
  position: relative;
}

.ui-date-time .date-input.with-time, .ui-date-time .time-input.with-time {
  margin: 0 10px 0 0;
}

.ui-date-time .date-input input[type="text"] {
  width: 110px;
}

.ui-date-time .time-input input[type="text"] {
  width: 90px;
}

.ui-date-time .date-input > i, .ui-date-time .time-input > i {
  margin: 0 5px 0 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.ui-date-time .date-picker {
  width: 195px;
  z-index: 10;
  background-color: #f7f7f7;
  padding: 5px;
  position: absolute;
  right: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
}

.ui-date-time .date-picker .previous-month {
  position: absolute;
  top: 10px;
  left: 5px;
}

.ui-date-time .date-picker .next-month {
  position: absolute;
  top: 10px;
  right: 5px;
}

.ui-date-time .date-picker .month-label {
  text-align: center;
  margin: 0 0 5px;
  font-size: 13px;
  line-height: 24px;
}

.ui-date-time .date-picker .bottom-controls {
  text-align: center;
  margin: 5px 0 0;
}

.ui-date-time .date-picker table {
  table-layout: fixed;
}

.ui-date-time .date-picker table td, .ui-date-time .date-picker table th {
  height: 24px;
  border: 2px solid #f7f7f7;
  font-size: 12px;
}

.ui-date-time .date-picker table th {
  color: #aeaeae;
}

.ui-date-time .date-picker table td {
  color: #868686;
  cursor: pointer;
  text-align: center;
  background-color: #e5e5e5;
}

.ui-date-time .date-picker table td:hover {
  background: #d3d3d3;
}

.ui-date-time .date-picker table td.not-in-month {
  cursor: auto;
  background: none;
}

.ui-date-time .date-picker table td.today {
  color: #333;
  background-color: #9a9a9a;
}

.ui-date-time .date-picker table td.selected {
  color: #fff;
  background-color: #000;
}

.ui-date-time .time-picker {
  width: 195px;
  z-index: 10;
  background-color: #f7f7f7;
  padding: 5px;
  position: absolute;
  right: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
}

.ui-date-time .time-picker .bottom-controls {
  text-align: center;
  margin: 5px 0 0;
}

.ui-date-navigation {
  line-height: 35px;
  display: inline-block;
}

.ui-date-navigation .label {
  padding: 0 20px;
}

.ui-deadline .days-before-choice, .ui-deadline .time-choice {
  float: left;
  display: inline-block;
}

.ui-deadline .days-before-choice .ui-choice > select, .ui-deadline .time-choice .ui-choice > select {
  width: 55px;
  padding: 0;
}

.ui-drop-down {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
}

.ui-drop-down ul {
  width: 180px;
  background-color: #f2f2f2;
  margin: 0;
  padding: 0;
  list-style: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, .3);
}

.ui-drop-down ul li {
  color: #333;
  text-align: left;
  cursor: pointer;
  padding: 7px 10px;
  line-height: 18px;
  text-decoration: none;
  display: block;
}

.ui-drop-down ul li.break {
  border-bottom: 1px solid #cbcbcb;
  padding: 0;
}

.ui-drop-down ul li:hover {
  color: #333;
  background-color: #e5e5e5;
}

.ui-drop-down ul li.disabled, .ui-drop-down ul li.disabled:hover {
  color: #d4d4cd;
  cursor: default;
  background-color: #f2f2f2;
}

.ui-element-update-pending {
  width: 25px;
  height: 25px;
  display: inline-block;
}

.ui-entity {
  display: inline-block;
}

.ui-error-message {
  z-index: 1;
  white-space: nowrap;
  background: #fe9a9b;
  border: 1px solid #d58283;
  padding: 0 10px;
  line-height: 25px;
  display: inline-block;
  position: absolute;
  top: -30px;
  left: 0;
}

.ui-error-message:before {
  content: "";
  border: 5px solid rgba(0, 0, 0, 0);
  border-top-color: #d58283;
  position: absolute;
  top: 25px;
  left: 10px;
}

.ui-field {
  vertical-align: top;
  margin: 5px;
  display: block;
}

.ui-field > label {
  float: left;
  padding: 5px 10px 5px 5px;
  font-weight: 700;
  display: inline-block;
}

.ui-field .widget {
  padding: 5px 0;
  display: inline-block;
  position: relative;
}

.ui-field .widget span.description {
  color: #a1a1a1;
  font-style: italic;
  display: block;
}

.ui-field.error .ui-text, .ui-field.error .ui-choice {
  outline: 1px solid red;
}

.ui-field-set {
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  margin: 15px 0;
}

.ui-flash-bar {
  color: #2c60a5;
  background-color: #90d4eb;
  margin: 0 0 10px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 24px;
}

.ui-flash-bar > * {
  margin: 0 5px;
}

.ui-flash-bar em {
  text-decoration: underline;
}

.ui-flash-bar.success {
  color: #227323;
  background-color: #91eb91;
}

.ui-flash-bar.warning {
  color: #96904d;
  background-color: #ffffcf;
}

.ui-flash-bar.error {
  color: #872325;
  background-color: #fe9a9b;
}

.ui-flash-bar.info {
  color: #2c60a5;
  background-color: #90d4eb;
}

.ui-flash-bar .ui-icon {
  float: left;
}

.ui-form {
  position: relative;
}

.ui-form .update-pending {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  right: 0;
}

.ui-form.inline, .ui-form.inline .ui-field {
  display: inline-block;
}

.ui-col-1 {
  width: 60px;
}

.ui-row-1 {
  height: 60px;
}

.ui-col-2 {
  width: 120px;
}

.ui-row-2 {
  height: 120px;
}

.ui-col-3 {
  width: 180px;
}

.ui-row-3 {
  height: 180px;
}

.ui-col-4 {
  width: 240px;
}

.ui-row-4 {
  height: 240px;
}

.ui-col-5 {
  width: 300px;
}

.ui-row-5 {
  height: 300px;
}

.ui-col-6 {
  width: 360px;
}

.ui-row-6 {
  height: 360px;
}

.ui-col-7 {
  width: 420px;
}

.ui-row-7 {
  height: 420px;
}

.ui-col-8 {
  width: 480px;
}

.ui-row-8 {
  height: 480px;
}

.ui-col-9 {
  width: 540px;
}

.ui-row-9 {
  height: 540px;
}

.ui-col-10 {
  width: 600px;
}

.ui-row-10 {
  height: 600px;
}

.ui-col-11 {
  width: 660px;
}

.ui-row-11 {
  height: 660px;
}

.ui-col-12 {
  width: 720px;
}

.ui-row-12 {
  height: 720px;
}

.ui-col-13 {
  width: 780px;
}

.ui-row-13 {
  height: 780px;
}

.ui-col-14 {
  width: 840px;
}

.ui-row-14 {
  height: 840px;
}

.ui-col-15 {
  width: 900px;
}

.ui-row-15 {
  height: 900px;
}

.ui-col-16 {
  width: 960px;
}

.ui-row-16 {
  height: 960px;
}

.ui-icon {
  font-family: IconsFont;
  font-size: inherit;
  font-variant: normal;
  text-rendering: auto;
  text-shadow: none;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  display: inline-block;
}

.ui-icon.icon-calendar:before {
  content: "";
}

.ui-icon.icon-cancel:before {
  content: "";
}

.ui-icon.icon-delete:before {
  content: "";
}

.ui-icon.icon-edit:before {
  content: "";
}

.ui-icon.icon-error:before {
  content: "";
}

.ui-icon.icon-hide:before {
  content: "";
}

.ui-icon.icon-info:before {
  content: "";
}

.ui-icon.icon-minus:before {
  content: "";
}

.ui-icon.icon-next:before {
  content: "";
}

.ui-icon.icon-plus:before {
  content: "";
}

.ui-icon.icon-previous:before {
  content: "";
}

.ui-icon.icon-success:before {
  content: "";
}

.ui-icon.icon-warning:before {
  content: "";
}

.ui-icon.icon-yes:before {
  content: "";
}

.ui-modal-overlay {
  height: 100%;
  text-align: center;
  width: 100%;
  background: rgba(255, 255, 255, .6);
  padding: 100px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.ui-modal {
  text-align: left;
  min-width: 560px;
  background-color: #f7f7f7;
  display: inline-block;
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
}

.ui-modal .modal-title {
  background-color: #f3f3f3;
  margin: 0;
  padding: 15px 30px;
  font-size: 32px;
  font-weight: 700;
  line-height: 45px;
}

.ui-modal .modal-title span {
  font-size: 16px;
  font-weight: 400;
}

.ui-modal .modal-content {
  margin: 25px 30px;
  overflow-x: auto;
}

.ui-modal .modal-content p {
  margin: 10px 0;
  font-size: 16px;
}

.ui-modal .modal-content .panel {
  margin: 0 -30px;
}

.ui-modal .primary-actions {
  float: right;
  padding: 0 30px 25px 0;
}

.ui-modal .secondary-actions {
  float: left;
  padding: 0 0 25px 30px;
}

.ui-radio {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  line-height: 25px;
  display: block;
}

.ui-radio.inline {
  display: inline-block;
}

.ui-radio input[type="radio"] {
  margin: 0 5px 0 0;
}

.ui-tag-input .ui-text {
  margin: 0 5px 0 0;
  display: inline-block;
}

.ui-tag-input .tags {
  margin: 5px 0 0;
}

.ui-tag-input .tags .tag {
  background-color: #cbcbcb;
  border-radius: 50px;
  margin: 0 5px 5px 0;
  padding: 2px 5px;
  display: inline-block;
}

.ui-tag-input .tags .tag span {
  vertical-align: middle;
  margin: 0 5px;
}

.ui-tag-input .tags .tag .ui-icon {
  vertical-align: middle;
}

.ui-tag-input .tags .tag .ui-icon:hover {
  color: #fa0019;
}

.ui-text {
  color: #000;
  height: 25px;
  border: 1px solid #ccc;
  outline: 0;
  padding: 2px 4px;
  font-size: 13px;
}

.ui-text:disabled {
  background-color: #ebebe4;
}

.ui-week-day-choice table {
  table-layout: fixed;
  text-align: center;
  border: 1px solid #f3f3f3;
}

.ui-week-day-choice table th {
  height: 30px;
  width: 55px;
  background-color: #f3f3f3;
  font-size: 18px;
}

.ui-week-day-choice table td {
  text-align: center;
  height: 30px;
  width: 55px;
  background-color: #fff;
}

body {
  color: #333;
  background: #fff;
  font-family: Open Sans, sans-serif;
  font-size: 13px;
}

.container {
  width: 995px;
  margin: 0 auto;
  padding: 0 0 100px;
}

.content-centered {
  text-align: center;
  margin: 125px 0;
  font-size: 16px;
}

.content-centered p {
  margin: 25px 0;
}

.content-loading {
  height: 32px;
  width: 32px;
  margin: 45px auto;
}

.query-string-filter {
  margin: 5px;
  display: inline-block;
}

.query-string-filter > label {
  float: left;
  padding: 5px 10px 5px 5px;
  font-weight: 700;
  display: inline-block;
}

.footer {
  text-align: right;
  color: silver;
  margin: 35px 0;
}

.header {
  padding: 15px 10px;
}

.header .logo {
  float: right;
  display: table;
}

.header .logo strong {
  color: #dc0c31;
  vertical-align: middle;
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  display: table-cell;
}

.header .logo img {
  float: left;
  width: 60px;
  height: auto;
  margin: 0 0 0 5px;
  display: block;
}

.menu {
  height: 50px;
  background-color: #000;
  margin: 0 0 40px;
  padding: 0 10px;
}

.menu a {
  color: #fff;
}

.menu ul.links {
  float: left;
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu ul.links li {
  float: left;
  font-size: 20px;
  line-height: 50px;
}

.menu ul.links li a {
  padding: 0 20px;
  text-decoration: none;
  display: block;
}

.menu ul.links li a:hover {
  background-color: #515151;
}

.menu ul.links li a.active {
  background-color: #d91436;
}

.menu .user {
  color: #fff;
  float: right;
  font-size: 13px;
  line-height: 50px;
}

.menu .user a:hover {
  text-decoration: none;
}

.login-page {
  width: 380px;
  margin: 150px auto;
}

.tours-page .tour-name span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.tours-page .schedule-table th.day {
  min-width: 60px;
}

.tours-page .schedule-table th.additional {
  min-width: 90px;
}

.drivers-page .driver-name span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.collection-editor label {
  float: left;
  margin: 10px 0;
  padding: 0 5px;
  font-weight: 700;
  line-height: 25px;
  display: inline-block;
}

.collection-editor em.date-separator {
  margin: 10px 0;
  padding: 0 5px;
  line-height: 25px;
  display: inline-block;
}

.collection-editor .ui-form .ui-button {
  margin: 10px 5px;
}

.collection-editor .collection-table label {
  margin: 1px 0 0;
  padding: 7px 5px;
  line-height: 24px;
}

.collection-editor .collection-table .ui-collection-output td {
  cursor: pointer;
}

.collection-editor .collection-table .ui-collection-output td.actions {
  cursor: auto;
}

.collection-editor .collection-table .ui-collection-output td.actions .ui-icon {
  color: #c2c2c2;
  cursor: pointer;
  font-size: 24px;
}

.collection-editor .collection-table .ui-collection-output td.actions .ui-icon:hover {
  color: #fa0019;
}

.collection-editor .collection-table .ui-collection-output thead th.actions {
  text-align: center;
  flex: 0 0 70px;
}

.collection-editor .collection-table .ui-collection-output tbody td.actions {
  text-align: center;
  flex: 0 0 60px;
}

.collection-editor .collection-table.disabled tbody {
  background-color: #ebebe4;
}

.collection-editor .collection-table.disabled td.actions .ui-icon:hover {
  cursor: auto;
  color: #c2c2c2;
}

.driver-group-overview-modal .schedule-table th.title {
  min-width: 140px;
}

.driver-group-overview-modal .schedule-table th.additional {
  min-width: 60px;
}

.driver-group-overview-modal .schedule-table th.driver {
  min-width: 80px;
  text-align: center;
}

.calendar-page table {
  width: 100%;
  table-layout: fixed;
  border: 3px solid #fff;
}

.calendar-page table th {
  color: #aeaeae;
  text-align: left;
  height: 45px;
  border: 3px solid #fff;
  padding: 2px 4px;
  font-size: 20px;
  font-weight: 400;
}

.calendar-page table td {
  height: 90px;
  vertical-align: top;
  color: #868686;
  cursor: pointer;
  background-color: #e5e5e5;
  border: 3px solid #fff;
  padding: 2px 4px;
}

.calendar-page table td.today {
  color: #fff;
  background: #000;
}

.calendar-page table td.not-in-month {
  background: none;
}

.calendar-page table td.event {
  color: #333;
  background: #9a9a9a;
}

.calendar-page table td > div {
  margin: 0 0 5px;
  font-size: 20px;
}

.calendar-page table td > div .event-name {
  font-size: 16px;
  display: block;
}

.schedule-table {
  width: 100%;
}

.schedule-table th {
  height: 35px;
  background-color: #eee;
  font-size: 16px;
}

.schedule-table th span {
  margin: 0 3px;
  font-size: 11px;
  font-weight: 400;
}

.schedule-table th.day {
  min-width: 90px;
}

.schedule-table th.additional {
  min-width: 60px;
}

.schedule-table td {
  text-align: center;
  height: 45px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 110px;
  border-bottom: 1px solid #eee;
  padding: 1px 3px;
  overflow: hidden;
}

.schedule-table td:first-child {
  width: 20%;
  text-align: left;
  padding-left: 5px;
  padding-right: 10px;
  font-size: 15px;
  font-weight: 700;
}

.schedule-table td:first-child span.group {
  font-size: 12px;
  font-weight: 400;
}

.schedule-table td:first-child span.group:hover {
  cursor: pointer;
  text-decoration: underline;
}

.schedule-table td.empty-list {
  color: #b3b3b3;
  text-align: center;
  font-size: 13px;
  font-style: italic;
  font-weight: normal;
}

.schedule-table td.tour-date {
  cursor: pointer;
}

.schedule-table td.tour-date.update-pending {
  cursor: default;
  background-color: #e3e3e3;
  padding: 8px;
}

.schedule-table td.tour-date.undefined, .schedule-table td.tour-date.inactive {
  background-color: #e3e3e3;
}

.schedule-table td.tour-date.canceled {
  color: #e3e3e3;
  background-color: #ff0202;
}

.schedule-table td.tour-date.open {
  background-color: #feffcb;
}

.schedule-table td.tour-date.open > span {
  margin: 0 3px;
}

.schedule-table td.driver-date {
  cursor: pointer;
}

.schedule-table td.driver-date.update-pending {
  cursor: default;
  background-color: #e3e3e3;
  padding: 8px;
}

.schedule-table td.driver-date.mountain-tour {
  background-color: #cf9;
}

.schedule-table td.driver-date.free {
  background-color: #e3e3e3;
}

.schedule-table td.driver-date.unknown {
  color: #e3e3e3;
  background-color: #525252;
}

.schedule-table td.driver-date.reserve {
  background-color: #feffcb;
}

.schedule-table td.driver-date.holiday {
  background-color: #fbd4b4;
}

.schedule-table td.driver-date.allowances {
  background-color: #ff67ff;
}

.schedule-table td.driver-date.sick-leave {
  color: #e3e3e3;
  background-color: #ff0202;
}

.schedule-table td.driver-date.accident {
  background-color: #ffff02;
}

.schedule-table td.driver-date.unpaid {
  background-color: #02ffff;
}

.schedule-table td.driver-date.paid {
  background-color: #e36d0b;
}

.schedule-table td.driver-date.vacation {
  background-color: #9acccc;
}

.schedule-table td.driver-date.paid-vacation {
  color: #e3e3e3;
  background-color: #7131a0;
}

.schedule-table td.driver-date.compensation {
  background-color: #67cc34;
}

.schedule-table td.driver-date.flex-free {
  background-color: #e3e3e3;
}

.schedule-table td.driver-date.flexible {
  color: #e3e3e3;
  background-color: #5c63cc;
}

.tour-date-driver-select > label {
  width: 180px;
  padding: 5px 10px 5px 5px;
  font-weight: 700;
  display: inline-block;
}

.tour-date-driver-select > span {
  width: 180px;
  padding: 5px 10px 5px 5px;
  display: inline-block;
}

.tour-date-driver-select > .ui-button {
  color: #fff;
  float: right;
  background-color: #333;
  border-color: #333;
  margin: 5px 0;
}

.tour-date-driver-select > .ui-button:hover:not(:disabled) {
  background-color: #000;
  border-color: #000;
}

.tour-date-driver-select > .ui-button .ui-icon {
  font-size: 22px;
}

.tour-date-driver-select .driver-selection {
  clear: both;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  margin: 10px 0;
  padding: 10px;
}

.tour-date-driver-select .driver-selection table.driver-table th, .tour-date-driver-select .driver-selection table.driver-table td {
  height: 25px;
  text-align: left;
  padding: 5px 0 5px 10px;
  line-height: 25px;
}

.tour-date-driver-select .driver-selection table.driver-table th:first-child, .tour-date-driver-select .driver-selection table.driver-table td:first-child {
  width: 40%;
}

.tour-date-driver-select .driver-selection table.driver-table th {
  color: #999;
  font-weight: 400;
}

.tour-date-driver-select .driver-selection table.driver-table td {
  border-top: 1px solid #f3f3f3;
}

.tour-date-driver-select .driver-selection table.driver-table td.name {
  font-size: 16px;
  font-weight: 700;
}

.tour-date-driver-select .driver-selection table.driver-table td > span {
  margin: 0 5px 0 0;
}

.tour-date-driver-select .driver-selection table.driver-table td.action {
  text-align: right;
}

.tour-date-driver-select .driver-selection .driver-tag {
  vertical-align: top;
  border-radius: 12px;
  padding: 0 8px;
  font-size: 9px;
  font-weight: 400;
  line-height: 12px;
  display: inline-block;
}

.tour-date-driver-select .driver-selection .driver-tag.free {
  background-color: #e3e3e3;
}

.tour-date-driver-select .driver-selection .driver-tag.unknown {
  color: #e3e3e3;
  background-color: #525252;
}

.tour-date-driver-select .driver-selection .driver-tag.reserve {
  background-color: #feffcb;
}

.tour-date-driver-select .driver-selection .driver-tag.holiday {
  background-color: #fbd4b4;
}

.tour-date-driver-select .driver-selection .driver-tag.allowances {
  background-color: #ff67ff;
}

.tour-date-driver-select .driver-selection .driver-tag.sick-leave {
  color: #e3e3e3;
  background-color: #ff0202;
}

.tour-date-driver-select .driver-selection .driver-tag.accident {
  background-color: #ffff02;
}

.tour-date-driver-select .driver-selection .driver-tag.unpaid {
  background-color: #02ffff;
}

.tour-date-driver-select .driver-selection .driver-tag.paid {
  background-color: #e36d0b;
}

.tour-date-driver-select .driver-selection .driver-tag.vacation {
  background-color: #9acccc;
}

.tour-date-driver-select .driver-selection .driver-tag.paid-vacation {
  color: #e3e3e3;
  background-color: #7131a0;
}

.tour-date-driver-select .driver-selection .driver-tag.compensation {
  background-color: #67cc34;
}

.tour-date-driver-select .driver-selection .driver-tag.flex-free {
  background-color: #e3e3e3;
}

.tour-date-driver-select .driver-selection .driver-tag.flexible {
  color: #e3e3e3;
  background-color: #5c63cc;
}

.tour-date-driver-select .driver-selection .clear-selection {
  text-align: right;
  padding: 5px 0 0;
}

/*# sourceMappingURL=index.53627b42.css.map */
