/* Icons
   ========================================================================== */

.ui-icon {
    display: inline-block;
    font-family: "IconsFont";
    font-size: inherit;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-rendering: auto;
    text-shadow: none;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 18px; // TODO: fix in Font Custom settings

    // calendar
    &.icon-calendar::before {
        content: "\f100";
    }

    // cancel
    &.icon-cancel::before {
        content: "\f101";
    }

    // delete
    &.icon-delete::before {
        content: "\f102";
    }

    // edit
    &.icon-edit::before {
        content: "\f10a";
    }

    // error
    &.icon-error::before {
        content: "\f103";
    }

    // hide
    &.icon-hide::before {
        content: "\f10b";
    }

    // info
    &.icon-info::before {
        content: "\f104";
    }

    // minus
    &.icon-minus::before {
        content: "\f10c";
    }

    // next
    &.icon-next::before {
        content: "\f105";
    }

    // plus
    &.icon-plus::before {
        content: "\f10d";
    }

    // previous
    &.icon-previous::before {
        content: "\f106";
    }

    // success
    &.icon-success::before {
        content: "\f107";
    }

    // warning
    &.icon-warning::before {
        content: "\f108";
    }

    // yes
    &.icon-yes::before {
        content: "\f109";
    }
}
