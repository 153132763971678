/* TourDate driver cell
   ========================================================================== */
@import "../vars";
@import "../colors";

.schedule-table td.driver-date {
    cursor: pointer;

    &.update-pending {
        padding: 8px;
        background-color: #e3e3e3;
        cursor: default;
    }

    &.mountain-tour {
        background-color: #ccff99;
    }

    @import "./fragments/driver-absence-types";
}
