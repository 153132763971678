/* Drop-down
   ========================================================================== */
@import "../vars";
@import "../colors";
@import "../mixins";

.ui-drop-down {
    position: absolute;
    user-select: none;

    ul {
        @include list-reset;
        @include box-shadow($blur: 5px);

        background-color: $background-color-panel;
        width: 180px;

        li {
            line-height: 18px;
            padding: 7px 10px;
            color: $color-text;
            text-decoration: none;
            text-align: left;
            display: block;
            cursor: pointer;

            &.break {
                padding: 0;
                border-bottom: 1px solid $background-color-break;
            }

            &:hover {
                color: $color-text-hover;
                background-color: darken($background-color-panel, $darken_block);
            }

            &.disabled,
            &.disabled:hover {
                color: $color-text-inactive;
                background-color: $background-color-panel;
                cursor: default;
            }
        }
    }
}
