/* Menu
   ========================================================================== */
@import "../vars";
@import "../colors";
@import "../mixins";

.menu {
    $menu-height: 50px;

    padding: 0 $container-padding;
    height: $menu-height;
    background-color: #000;
    margin: 0 0 40px;

    a {
        color: #fff;
    }

    // links
    ul.links {
        @include list-reset;
        float: left;

        li {
            float: left;
            font-size: 20px;
            line-height: $menu-height;

            a {
                display: block;
                padding: 0 20px;
                text-decoration: none;

                &:hover {
                    background-color: #515151;
                }
            }

            & a.active {
                background-color: #d91436;
            }
        }
    }

    // user
    .user {
        color: #fff;
        float: right;
        font-size: 13px;
        line-height: $menu-height;

        a:hover {
            text-decoration: none;
        }
    }
}
