/* Collection editor
   ========================================================================== */

.collection-editor {
    label {
        display: inline-block;
        font-weight: 700;
        float: left;
        line-height: 25px;
        padding: 0 5px;
        margin: 10px 0;
    }

    em.date-separator {
        display: inline-block;
        line-height: 25px;
        padding: 0 5px;
        margin: 10px 0;
    }

    .ui-form .ui-button {
        margin: 10px 5px;
    }

    .collection-table {
        label {
            line-height: 24px;
            margin: 1px 0 0;
            padding: 7px 5px;
        }

        .ui-collection-output {
            td {
                cursor: pointer;
            }

            td.actions {
                cursor: auto;

                .ui-icon {
                    color: $background-color-neutral;
                    cursor: pointer;
                    font-size: 24px;

                    &:hover {
                        color: $background-color-negative;
                    }
                }
            }

            thead th.actions {
                flex: 0 0 70px;
                text-align: center;
            }

            tbody td.actions {
                flex: 0 0 60px;
                text-align: center;
            }
        }

        &.disabled {
            tbody {
                background-color: $background-color-inactive;
            }

            td.actions .ui-icon:hover {
                cursor: auto;
                color: $background-color-neutral;
            }
        }
    }
}
