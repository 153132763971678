/* Form: Collection
   ========================================================================== */

.ui-collection {
    .ui-field {
        display: inline-block;

        &.actions .ui-icon {
            padding: 5px 0;
            margin: 0 5px 0 0;
            line-height: $ui-field-item-height;
            cursor: pointer;

            &:hover {
                color: $background-color-negative;
            }
        }
    }
}
