/* Form: Choice
   ========================================================================== */

.ui-choice {
    display: inline-block;

    &.auto-select {
        .ui-button,
        .ui-element-update-pending {
            float: right;
        }

        .choices {
            clear: both;
            background-color: #fff;
            border: 1px solid #f3f3f3;
            overflow-y: auto;
            padding: 4px 20px;
        }
    }

    select {
        border: 1px solid #ccc;
        color: #000;
        font-size: $font-size;
        height: $ui-field-item-height;
        outline: 0;
        padding: 2px 4px;
    }
}
