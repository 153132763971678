/* Form: Tag input
   ========================================================================== */

.ui-tag-input {
    .ui-text {
        display: inline-block;
        margin: 0 5px 0 0;
    }

    .tags {
        margin: 5px 0 0;

        .tag {
            background-color: #cbcbcb;
            border-radius: 50px;
            display: inline-block;
            margin: 0 5px 5px 0;
            padding: 2px 5px;

            span {
                vertical-align: middle;
                margin: 0 5px;
            }

            .ui-icon {
                vertical-align: middle;

                &:hover {
                    color: $background-color-negative;
                }
            }
        }
    }
}
