/* Form: Error message
   ========================================================================== */

.ui-error-message {
    $arrow-size: 5px;

    display: inline-block;
    position: absolute;
    top: -($ui-field-item-height + $arrow-size);
    left: 0;
    z-index: 1;
    line-height: $ui-field-item-height;
    white-space: nowrap;
    padding: 0 10px;
    border: 1px solid #d58283;
    background: #fe9a9b;

    &::before {
        border: $arrow-size solid transparent;
        border-top-color: #d58283;
        content: "";
        left: $arrow-size * 2;
        position: absolute;
        top: $ui-field-item-height;
    }
}
