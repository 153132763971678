/* TourDate driver select
   ========================================================================== */
@import "../vars";
@import "../colors";
@import "../mixins";

.tour-date-driver-select {
    @extend .clear-fix;

    > label {
        display: inline-block;
        font-weight: 700;
        padding: 5px 10px 5px 5px;
        width: 180px;
    }

    > span {
        display: inline-block;
        padding: 5px 10px 5px 5px;
        width: 180px;
    }

    > .ui-button {
        background-color: #333333;
        border-color: #333333;
        color: #ffffff;
        float: right;
        margin: 5px 0;

        &:hover:not(:disabled) {
            background-color: #000000;
            border-color: #000000;
        }

        .ui-icon {
            font-size: 22px;
        }
    }

    .driver-selection {
        background-color: #fff;
        clear: both;
        padding: 10px;
        margin: 10px 0;
        border-bottom: 1px solid #ccc;

        table.driver-table {
            th,
            td {
                line-height: 25px;
                height: 25px;
                padding: 5px 0 5px 10px;
                text-align: left;

                &:first-child {
                    width: 40%;
                }
            }

            th {
                font-weight: 400;
                color: #999;
            }

            td {
                border-top: 1px solid #f3f3f3;

                &.name {
                    font-size: 16px;
                    font-weight: 700;
                }

                > span {
                    margin: 0 5px 0 0;
                }

                &.action {
                    text-align: right;
                }
            }
        }

        .driver-tag {
            border-radius: 12px;
            display: inline-block;
            font-size: 9px;
            line-height: 12px;
            padding: 0 8px;
            font-weight: 400;
            vertical-align: top;

            @import "./fragments/driver-absence-types";
        }

        .clear-selection {
            text-align: right;
            padding: 5px 0 0;
        }
    }
}
