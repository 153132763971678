/* Form: DateTime
   ========================================================================== */
@import "../mixins";

.ui-date-time {
    display: inline-block;

    // inputs
    .date-input,
    .time-input {
        position: relative;
        display: inline-block;
        float: left;

        &.with-time {
            margin: 0 10px 0 0;
        }
    }

    .date-input input[type="text"] {
        width: 110px;
    }

    .time-input input[type="text"] {
        width: 90px;
    }

    // input icons
    .date-input > i,
    .time-input > i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        margin: 0 5px 0 0;
    }

    // date picker
    .date-picker {
        @include box-shadow;

        position: absolute;
        background-color: #f7f7f7;
        padding: 5px;
        width: 195px;
        right: 0;
        z-index: 10;

        .previous-month {
            position: absolute;
            left: 5px;
            top: 10px;
        }

        .next-month {
            position: absolute;
            right: 5px;
            top: 10px;
        }

        .month-label {
            font-size: 13px;
            line-height: 24px;
            margin: 0 0 5px;
            text-align: center;
        }

        .bottom-controls {
            margin: 5px 0 0;
            text-align: center;
        }

        table {
            table-layout: fixed;

            td,
            th {
                border: 2px solid #f7f7f7;
                font-size: 12px;
                height: 24px;
            }

            th {
                color: #aeaeae;
            }

            td {
                background-color: #e5e5e5;
                color: #868686;
                cursor: pointer;
                text-align: center;

                &:hover {
                    background: darken(#e5e5e5, 7%);
                }

                &.not-in-month {
                    background: none;
                    cursor: auto;
                }

                &.today {
                    background-color: #9a9a9a;
                    color: #333;
                }

                &.selected {
                    background-color: #000;
                    color: #fff;
                }
            }
        }
    }

    // time picker
    .time-picker {
        @include box-shadow;

        position: absolute;
        background-color: #f7f7f7;
        padding: 5px;
        width: 195px;
        right: 0;
        z-index: 10;

        .bottom-controls {
            margin: 5px 0 0;
            text-align: center;
        }
    }
}
