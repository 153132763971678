/* Tours page
   ========================================================================== */

.tours-page {
    .tour-name span:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    .schedule-table th.day {
        min-width: 60px;
    }

    .schedule-table th.additional {
        min-width: 90px;
    }
}
