/* Form: Week day choice
   ========================================================================== */

.ui-week-day-choice table {
    $table-height: 30px;

    border: 1px solid #f3f3f3;
    table-layout: fixed;
    text-align: center;

    th {
        background-color: #f3f3f3;
        font-size: 18px;
        height: $table-height;
        width: 55px;
    }

    td {
        background-color: #fff;
        text-align: center;
        height: $table-height;
        width: 55px;
    }
}
