/* Form: Radio
   ========================================================================== */

.ui-radio {
    display: block;
    line-height: $ui-field-item-height;
    user-select: none;
    cursor: pointer;

    &.inline {
        display: inline-block;
    }

    input[type="radio"] {
        margin: 0 5px 0 0;
    }
}
