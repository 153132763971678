/* Form: Text
   ========================================================================== */

.ui-text {
    border: 1px solid #ccc;
    color: #000;
    font-size: $font-size;
    height: $ui-field-item-height;
    outline: 0;
    padding: 2px 4px;

    &:disabled {
        background-color: $background-color-inactive;
    }
}
