/* Form: Button
   ========================================================================== */
@import "../vars";
@import "../colors";

.ui-button {
    display: inline-block;
    margin: 0 1px;
    vertical-align: top;
    user-select: none;
    outline: 0;

    // default size: regular
    height: 25px;
    line-height: 23px;
    font-size: 12px;
    font-weight: 700;
    padding: 0 5px;

    // default type: neutral
    text-decoration: none;
    color: $color-text;
    background-color: $background-color-neutral;
    border: 1px solid $background-color-neutral;

    &:disabled {
        opacity: 0.5;
    }

    &:hover:not(:disabled) {
        background-color: darken($background-color-neutral, $darken_button);
    }

    &.btn-positive {
        color: $color-text-inverted;
        background-color: $background-color-positive;
        border-color: $background-color-positive;

        &:hover:not(:disabled) {
            background-color: darken($background-color-positive, $darken_button);
        }
    }

    &.btn-neutral {
        color: $color-text;
        background-color: $background-color-neutral;
        border-color: $background-color-neutral;

        &:hover:not(:disabled) {
            background-color: darken($background-color-neutral, $darken_button);
        }
    }

    &.btn-negative {
        color: $color-text-inverted;
        background-color: $background-color-negative;
        border-color: $background-color-negative;

        &:hover:not(:disabled) {
            background-color: darken($background-color-negative, $darken_button);
        }
    }

    &.btn-warning {
        color: $color-text;
        background-color: $background-color-warning;
        border-color: $background-color-warning;

        &:hover:not(:disabled) {
            background-color: darken($background-color-warning, $darken_button);
        }

        &.btn-secondary:hover:not(:disabled) {
            color: $color-text-hover;
        }
    }

    &.btn-info {
        color: $color-text-inverted;
        background-color: $background-color-info;
        border-color: $background-color-info;

        &:hover:not(:disabled) {
            background-color: darken($background-color-info, $darken_button);
        }
    }

    &.btn-secondary {
        color: $color-text;
        background-color: $background-color-panel;
        border-width: 1px;

        &:hover:not(:disabled) {
            color: $color-text-inverted-hover;
        }
    }

    &.btn-small {
        height: 15px;
        line-height: 13px;
        font-size: 10px;
        font-weight: 700;
        padding: 0 5px;
    }

    &.btn-regular {
        height: 15px;
        line-height: 13px;
        font-size: 12px;
        font-weight: 700;
        padding: 0 5px;
    }

    &.btn-large {
        height: 35px;
        line-height: 33px;
        width: auto;
        font-size: 14px;
        font-weight: 700;
        padding: 0 15px;
    }

    &.btn-x-large {
        height: 45px;
        line-height: 43px;
        font-size: 16px;
        font-weight: 700;
        padding: 0 25px;
    }

    &.btn-icon-small {
        width: 15px;
        height: 15px;
        line-height: 13px;
        padding: 0;
    }

    &.btn-icon,
    &.btn-icon-regular {
        width: 25px;
        height: 25px;
        line-height: 23px;
        padding: 0;
    }

    &.btn-icon-large {
        width: 35px;
        height: 35px;
        line-height: 33px;
        padding: 0;
    }

    &.btn-icon-x-large {
        width: 45px;
        height: 45px;
        line-height: 43px;
        padding: 0;
    }

    &.btn-link {
        background-color: transparent;
        border: none;
        font-weight: 400;
        text-decoration: underline;

        &:hover:not(:disabled) {
            background-color: transparent;
        }
    }

    &.btn-rounded {
        border-radius: 50px;
    }

    &.btn-no-borders {
        background-color: transparent;
        border: none;

        &:hover:not(:disabled) {
            background-color: transparent;
        }
    }

    .ui-icon {
        margin: 0 3px;
        vertical-align: top;
    }
}

.confirmation-button {
    display: inline-block;
}
