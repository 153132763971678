/* Form
   ========================================================================== */

.ui-form {
    position: relative;

    .update-pending {
        position: absolute;
        right: 0;
        top: 0;
        width: 32px;
        height: 32px;
    }

    &.inline,
    &.inline .ui-field {
        display: inline-block;
    }
}
