/* Schedule table
   ========================================================================== */
@import "../vars";
@import "../colors";

.schedule-table {
    width: 100%;

    th {
        height: 35px;
        background-color: $background-color-header;
        font-size: 16px;

        span {
            margin: 0 3px;
            font-size: 11px;
            font-weight: 400;
        }

        &.day {
            min-width: 90px;
        }

        &.additional {
            min-width: 60px;
        }
    }

    td {
        border-bottom: 1px solid $background-color-header;
        padding: 1px 3px;
        text-align: center;
        height: 45px;
        user-select: none;
        cursor: default;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 110px;

        &:first-child {
            width: 20%;
            text-align: left;
            font-weight: 700;
            font-size: 15px;
            padding-left: 5px;
            padding-right: 10px;

            span.group {
                //display: block;
                font-size: 12px;
                font-weight: 400;

                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }

        &.empty-list {
            color: lighten($color-text, 50%);
            font-size: $font-size;
            font-style: italic;
            font-weight: normal;
            text-align: center;
        }
    }
}
